import type { FC } from "react"
import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { socialModel, useSocialHistory } from "social-history"

import { WrapUpSection } from "./WrapUpSection"

const SocialHistorySection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { socialHistory, isLoading, total } = useSocialHistory(patientId, encounterId)

  return (
    <WrapUpSection
      subSectionTitle="Social History"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No history registered during this encounter"
      className="w-3/4 self-end"
    >
      <StackedListContainer data={socialHistory ?? []} itemModelBuilder={(item) => socialModel(item)} />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { SocialHistorySection }
