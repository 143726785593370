import type { FC } from "react"
import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { familyHistoryModelBuilder, useFamilyHistory } from "family-history"

import { WrapUpSection } from "./WrapUpSection"

const FamilyHistorySection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { familyMemberHistories, isLoading, total } = useFamilyHistory(patientId, encounterId)

  return (
    <WrapUpSection
      subSectionTitle="Family History"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No history registered during this encounter"
      className="w-3/4 self-end"
    >
      <StackedListContainer
        data={familyMemberHistories ?? []}
        itemModelBuilder={(item) => familyHistoryModelBuilder(item)}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { FamilyHistorySection }
