import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { Annotation, Money, ServiceRequest } from "fhir"
import type { FC } from "react"

import { Accordion, ModulesId, StackedListItem } from "commons"
import { labOrderItemModelBuilder } from "commons/labs"
import { BILLING_TYPES_CODES, laboratoryOrderStatusCodes } from "data"
import { useLaboratoryOrders } from "laboratory-orders/hooks"
import { useChartContext } from "chart-view"

import { WrapUpSection } from "./WrapUpSection"

const LabsSection: FC<Props> = ({ patientId, organizationId, isExemptLabPayment, encounterId, icon }) => {
  const { showModule } = useChartContext()

  const orderStatus = laboratoryOrderStatusCodes.map(({ code }) => code)

  const { laboratoryOrders, isLoading, total } = useLaboratoryOrders({
    organizationId,
    patientId,
    statusFilter: orderStatus,
    enabled: true,
    encounter: encounterId,
  })

  const goToOrderDetails = (orderId: string) => {
    showModule({ module: ModulesId.LABSORDER, moduleParams: { orderId } })
  }

  const renderDetails = ({
    panels,
    showPrice,
    notes,
  }: {
    panels: { profile: ServiceRequest; price?: Money }[]
    showPrice: boolean
    notes?: Annotation[]
  }) => {
    const labNotes = notes?.filter(({ text }) => !!text)
    return (
      <>
        {!!labNotes?.length && (
          <div className="flex flex-col text-sm mb-2">
            <span className="font-medium">Notes:</span>
            {labNotes.map(({ text }, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        )}

        {panels.length && (
          <div className="flex flex-col text-sm">
            <span className="font-medium">Additional Testing/Combos:</span>
            {panels.map((panel) => (
              <div className="py-1 border-b last:border-none flex items-center justify-between" key={panel.profile.id}>
                <span> {panel.profile.code?.coding?.[0].display}</span>
                {showPrice && (
                  <span className="ml-4">
                    {panel.price ? (
                      `${panel.price?.value?.toFixed(2)} ${panel.price?.currency}`
                    ) : (
                      <small className="text-gray-400">No price</small>
                    )}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  return (
    <WrapUpSection
      sectionTitle="Lab orders"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No orders placed during this encounter"
      className="w-10/12 self-end pb-2 z-50 divide-y"
    >
      <Accordion
        data={laboratoryOrders ?? []}
        headerTemplate={(labData) => (
          <StackedListItem
            className="pt-2 pb-4 pr-7"
            modelData={labOrderItemModelBuilder({
              data: { ...labData, order: { ...labData.order, occurrence: undefined }, billingType: "" },
              goToOrderDetails,
              showPrice: !isExemptLabPayment && labData.billingType !== BILLING_TYPES_CODES.INSURANCE,
              allowOnlyExternalAction: true,
            })}
          />
        )}
        headerClassName="paddingless @container"
        expandIcon="pi pi-plus text-sm right-0"
        collapseIcon="pi pi-minus text-sm right-0"
        contentTemplate={({ panels, combo, billingType, order: { note } }) =>
          renderDetails({
            panels: [...panels, ...(combo ? [{ profile: combo.laboratoryCombo, price: combo?.price }] : [])],
            showPrice: !isExemptLabPayment && billingType !== BILLING_TYPES_CODES.INSURANCE,
            notes: note,
          })
        }
      />
    </WrapUpSection>
  )
}

type Props = {
  encounterId: string
  patientId: string
  organizationId: string
  isExemptLabPayment?: boolean
  icon: IconDefinition
}

export { LabsSection }
