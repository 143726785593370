import { faCalendarClock, faCalendarDay, faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { faUser, faUserDoctor } from "@fortawesome/pro-solid-svg-icons"
import { format } from "date-fns/format"
import { type FC, Fragment } from "react"

import { formatsByTypes } from "data"

import { EmptyMessage } from "../../components/EmptyMessage"
import { StackedListContainer } from "../../components/StackedListContainer"
import type { StackedListItemProps } from "../../components/StackedListItem"
import type { ActivityEventData } from "../types"

const ActivityHistory: FC<Props> = ({ activityRecordsByMR }) => {
  const hasActivityRecord = !!(activityRecordsByMR && Object.keys(activityRecordsByMR).length)

  return hasActivityRecord ? (
    <div className="flex flex-col w-full h-full overflow-y-auto">
      {hasActivityRecord && (
        <>
          {Object.entries(activityRecordsByMR).map(([mrId, mrActivity]) => (
            <Fragment key={mrId}>
              <div className="relative border-t first:mt-3 mt-6 mb-2">
                <span className="font-medium text-sm bg-white text-gray-500 px-2 absolute top-0 -translate-y-1/2">
                  {mrActivity[0]?.entity?.[0]?.name}
                </span>
              </div>
              <StackedListContainer
                key={mrId}
                data={mrActivity as ActivityEventData[]}
                className="grid grid-flow-row list-none w-full px-3 !divide-none"
                itemModelBuilder={(item) => modelBuilder(item)}
              />
            </Fragment>
          ))}
        </>
      )}
    </div>
  ) : (
    <EmptyMessage
      icon={faCalendarClock}
      message="No activity recorded for this order"
      subMessage={false}
      className="h-full content-center"
    />
  )
}

const modelBuilder = ({ participants, from, to, recorded, event }: ActivityEventData): StackedListItemProps => {
  return {
    leftData: [
      {
        lineItems: [{ name: "Event", value: event?.[0]?.display ?? event?.[0]?.code ?? "Unspecified" }],
      },
      {
        lineItems: [
          ...participants.map((p) => ({
            name: p.type,
            value: `${p.requestor ? "Requestor: " : ""}${p.name}`,
            icon: p.type === "Practitioner" ? faUserDoctor : faUser,
          })),
          {
            name: "Date",
            value: format(recorded, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
            icon: faCalendarDays,
          },
          ...(from
            ? [
                {
                  name: "Rescheduled from",
                  value: format(from, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
                  icon: faCalendarClock,
                },
              ]
            : []),
          ...(to
            ? [
                {
                  name: "Rescheduled to",
                  value: format(to, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
                  icon: faCalendarDay,
                },
              ]
            : []),
        ],
      },
    ],
  }
}

type Props = {
  activityRecordsByMR?: Record<string, ActivityEventData[]>
}

export { ActivityHistory }
