import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { DiagnosticReport } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import type { CustomError } from "../../types"
import { labOrderDetailQueryKey } from "./query-keys"

const useReleaseLabResult = (laboratoryOrderId?: string, onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const releaseFn = async (drId: string, releaseDate: string = new Date().toISOString()) =>
    patch("DiagnosticReport", drId, { released: releaseDate } as DiagnosticReport)

  const { mutate, isPending } = useMutation({
    mutationFn: releaseFn,
    onError: async (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: laboratoryOrderId ? labOrderDetailQueryKey.details(laboratoryOrderId) : ["patient/laboratoryOrders"],
        type: laboratoryOrderId ? "all" : undefined,
      })
      displayNotificationSuccess(`Results released successfully!`)
      // datadogLogs.logger.info(`Results released successfully!`, { data })
    },
    onSettled,
  })

  return { releaseResult: mutate, isUpdating: isPending }
}

export { useReleaseLabResult }
