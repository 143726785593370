import { type FC, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { faPlay } from "@fortawesome/pro-solid-svg-icons"

import { usePatientContext } from "patients"
import { Button, ConfirmDialog } from "commons"
import { CPOE_STEPS, useCPOEContext } from "orders"

import { useOpenEncounter } from "../hooks"
import { EncounterFormContainer } from "./EncounterFormContainer"
import { EncounterFinishButton } from "./EncounterFinishButton"

const EncounterContainer: FC = () => {
  const { patient, patientId, patientRef } = usePatientContext()
  const { ordersCount, showCpoeOverlay } = useCPOEContext()
  const [startEncounter, setStartEncounter] = useState(false)
  const { openEncounter, isLoading } = useOpenEncounter(patientId)

  const [searchParams, setSearchParams] = useSearchParams()
  const [showCheckoutConfirm, setShowCheckoutConfirm] = useState(false)

  const closeForm = () => {
    setStartEncounter(false)
  }

  const showEncounterDetails = () => {
    if (ordersCount === 0) {
      const params = new URLSearchParams({
        ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
        view: "encounters",
      })
      setSearchParams(params)
    } else setShowCheckoutConfirm(true)
  }

  return (
    <div>
      {!openEncounter ? (
        <>
          <Button
            label="Start encounter"
            loading={isLoading}
            onClick={() => setStartEncounter(true)}
            icon={faPlay}
            font="font-normal"
            className="button-banner button-primary"
          />
          <EncounterFormContainer patient={patient} closeForm={closeForm} startEncounter={startEncounter} />
        </>
      ) : (
        <>
          <EncounterFinishButton
            patientId={patientId}
            patientRef={patientRef}
            encounter={openEncounter}
            onEncounterFinished={closeForm}
            buttonClassName="button-banner bg-transparent rounded-lg"
            onStopClicked={showEncounterDetails}
          />
          <ConfirmDialog
            visible={showCheckoutConfirm}
            hideDialog={() => setShowCheckoutConfirm(false)}
            actionName="Checkout"
            rejectAction="Resume"
            confirmText="You have items on the cart that need to be checkout before closing the encounter. Do you want to go to checkout page?"
            onConfirm={() => showCpoeOverlay(CPOE_STEPS.SUMMARY)}
          />
        </>
      )}
    </div>
  )
}

export { EncounterContainer }
