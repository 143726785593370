import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { MedicationKnowledge, MedicationRequest } from "fhir"
import { type FC, useState } from "react"

import { Accordion, MedicationKnowledgeDetails, useChargeItemDefinitions } from "commons"
import { useMedicationRequestDataBind, useMedicationRequests } from "commons/meds"
import { MedicationRequestListItem } from "medication-requests"

import { WrapUpSection } from "./WrapUpSection"
import { renderDetails } from "./utils"

const NutrasSection: FC<Props> = ({ organizationId, patientId, encounterId, icon }) => {
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const { isLoading, medicationRequests, medicationKnowledges, medCodes, medicationDispenses, total } =
    useMedicationRequests({
      patientId,
      category: "nutraceutical",
      statusFilter: ["active", "draft"],
      perPage: 100,
      page: 1,
      encounter: encounterId,
    })
  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medicationDispenses,
  })

  return (
    <WrapUpSection
      sectionTitle="Nutraceuticals"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No nutraceuticals orders placed during this encounter"
      className="w-full self-end"
    >
      <Accordion
        data={medicationRequestData}
        headerTemplate={(item) => (
          <MedicationRequestListItem
            key={`${item.medicationRequestInfo.id}-${item.medicationRequestInfo.status}`}
            medicationData={item}
            onSelectMK={(mk) => setSelectedMK({ mk, mr: item.medicationRequestInfo })}
            showActions={false}
            imageClassName="w-24 h-24"
            itemClassName="pr-4"
          />
        )}
        headerClassName="paddingless @container"
        expandIcon="pi pi-plus text-sm right-0 top-6"
        collapseIcon="pi pi-minus text-sm right-0 top-6"
        contentTemplate={({ medicationRequestInfo: { note } }) =>
          renderDetails({
            notes: note,
            className: "@lg:pl-[8.7rem] @3xl:pl-[10.5rem]",
          })
        }
      />

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        mr={selectedMK?.mr}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  organizationId: string
  icon: IconDefinition
}

export { NutrasSection }
