import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { MedicationKnowledge, MedicationRequest } from "fhir"
import { type FC, useState } from "react"

import { Accordion, MedicationKnowledgeDetails, StackedListItem } from "commons"
import { type MedicationRequestData, useMedicationRequests } from "commons/meds"
import { prescriptionItemModel, useMedicationRequestDataBind } from "eprescribe"

import { WrapUpSection } from "./WrapUpSection"
import { renderDetails } from "./utils"

const MedsSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const { isLoading, medicationRequests, medicationKnowledges, total } = useMedicationRequests({
    patientId,
    category: "medication",
    statusFilter: ["active", "draft"],
    perPage: 100,
    page: 1,
    encounter: encounterId,
  })

  const { medicationRequestData } = useMedicationRequestDataBind({ medicationRequests, medicationKnowledges })

  const previewMedOrMK = (mrData: MedicationRequestData) => {
    setSelectedMK({ mk: mrData.medicationKnowledge, mr: mrData.medicationRequestInfo })
  }

  return (
    <WrapUpSection
      sectionTitle="Rx"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No prescriptions order placed during this encounter"
      className="w-10/12 self-end"
    >
      <Accordion
        data={medicationRequestData}
        headerTemplate={(item) => (
          <StackedListItem
            className="pt-2 pb-4 pr-7"
            modelData={prescriptionItemModel({ mrData: item, preview: previewMedOrMK })}
          />
        )}
        headerClassName="paddingless @container"
        expandIcon="pi pi-plus text-sm right-0"
        collapseIcon="pi pi-minus text-sm right-0"
        contentTemplate={({ medicationRequestInfo: { note } }) =>
          renderDetails({
            notes: note,
          })
        }
      />

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
        mr={selectedMK?.mr}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { MedsSection }
