import {
  type CarePlan,
  type CodeableConcept,
  type Coding,
  type Dosage,
  type Medication,
  type MedicationAdministration,
  type MedicationKnowledge,
  type MedicationRequest,
  type Observation,
  type Patient,
  type Procedure,
  type Quantity,
  type Reference,
  asReference,
  isActivityDefinition,
  isMedicationKnowledge,
  isMedicationRequest,
} from "fhir"

import { mrCategoryCodes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getCommonCode, isRefrigeratedMedicationKnowledge } from "utils"

import { ProcedureProductType } from "../data"
import {
  type CalculatorOutput,
  type ConfigurationItem,
  type InventoryData,
  type ProcedureData,
  type Supplement,
  bodyZonesCodes,
  ProcedureKind,
} from "../types"

const generateCalculatorResultFromCarePlan = (cp: CarePlan): CalculatorOutput => {
  const ads = cp.contained?.filter(isActivityDefinition) ?? []
  const mks = ads.flatMap((ad) => (ad?.contained ?? []) as MedicationKnowledge[])
  const supplements = cp.contained?.filter(isMedicationRequest)?.reduce((acc, mr) => {
    const mk = mr.contained?.filter(isMedicationKnowledge)?.[0]

    const supplement: Supplement | undefined = mr && mk ? { mr, mk } : undefined
    return [...acc, ...(supplement ? [supplement] : [])]
  }, Array<Supplement>())

  return {
    suggestedMeds: mks.filter((med) => !!med.catalogHeader),
    recommended: ads.map((ad) => ({
      codeableConcept: ad?.product?.CodeableConcept ?? {},
      dosage: ad?.dosage?.[0] ?? {},
      relatedMedsIds: ((ad?.contained ?? []) as MedicationKnowledge[]).flatMap((mk) => mk.id!),
    })),
    notes: cp?.note ?? [],
    supplements,
  }
}

const getDoseNumberValue = (dose: string, doseUnit: string | undefined = "mg") => {
  let number = 0
  try {
    number = parseFloat(dose.replace(doseUnit, ""))
  } catch (_) {
    number = 0
  }

  return number
}

const getProcedureKind = (procedure: Procedure) =>
  procedure?.code?.coding?.[0]?.code === "massage-procedure"
    ? ProcedureKind.massage
    : procedure?.code?.coding?.[0]?.code === "botox-procedure"
      ? ProcedureKind.botox
      : ProcedureKind.pellet

const convertProcedureKindToProductType = (procedureKind: ProcedureKind) => {
  switch (procedureKind) {
    case ProcedureKind.botox:
      return ProcedureProductType.botox
    case ProcedureKind.pellet:
      return ProcedureProductType.pellet
    default:
      return ProcedureProductType.pellet
  }
}

const getInitialValues = (
  patient: Patient,
  icd10Codes: CodeableConcept[] = [],
  defaultRequeter?: Reference,
  encounter?: Reference,
  canonical?: string,
  planId?: string,
): ProcedureData => {
  return {
    procedure: {
      status: "preparation",
      subject: asReference(patient),
      code: undefined,
      instantiatesCanonical: canonical ? [canonical] : undefined,
      reasonCode: icd10Codes.map((code) => ({ coding: [code] })),
      note: [{ text: "" }],
      performed: {
        dateTime: new Date().toISOString(),
      },
      performer: [
        {
          actor: defaultRequeter ?? {},
          function: {
            text: "Healthcare professional",
            coding: [
              {
                code: "223366009",
                system: SYSTEM_VALUES.SNOMED_SCT,
                display: "Healthcare professional",
              },
            ],
          },
        },
      ],
      encounter,
      basedOn: [{ id: planId, resourceType: "CarePlan" }],
    },
    configurationItem: [],
    deletedMedications: [],
    qrId: "",
    labData: undefined,
  }
}

const getMAInitialValues = (
  patientRef: Reference,
  medicationKnowledge: MedicationKnowledge,
  unitCount: number,
  doseQuantity: Quantity,
  invData: InventoryData[],
): MedicationAdministration => ({
  status: "preparation",
  subject: patientRef,
  performer: undefined,
  dosage: {
    dose: {
      ...doseQuantity,
      value: (doseQuantity?.value as number) * unitCount,
    },
    site: undefined,
  },
  partOf: undefined, //to be filled on save
  request: undefined, //to be filled on save
  reasonCode: [
    {
      coding: [
        {
          code: "b",
          system: SYSTEM_VALUES.MEDICATION_REASON_CODE,
          display: "Given as Ordered",
        },
      ],
    },
  ],
  effective: {
    Period: {
      start: new Date().toISOString(),
    },
  },
  medication: {
    Reference: {
      localRef: "med1",
    },
  },
  contained: getMAContainedInitialValue(invData, unitCount || 1, medicationKnowledge?.code),
})

const createBatchObject = (
  batch: InventoryData,
  medCode: CodeableConcept | undefined,
  usedQty: number,
  id: string | undefined = undefined,
): Medication =>
  ({
    ...(id ? { id } : {}),
    amount: { numerator: { value: usedQty } },
    code: medCode,
    batch,
    resourceType: "Medication",
  }) as Medication

const getMAContainedInitialValue = (
  batches: InventoryData[],
  unitCount: number,
  medCode: CodeableConcept | undefined,
  isFirstBatch = true,
): Medication[] => {
  const eligibleBatch = batches
    .filter((batch) => batch.quantity >= unitCount)
    .reduce((min, batch) => (min && min.quantity < batch.quantity ? min : batch), null as InventoryData | null)

  if (eligibleBatch) {
    return [createBatchObject(eligibleBatch, medCode, unitCount, isFirstBatch ? "med1" : undefined)]
  }

  const largestBatch = batches.reduce(
    (max, batch) => (max && max.quantity > batch.quantity ? max : batch),
    null as InventoryData | null,
  )

  if (!largestBatch) return []

  const remainingBatches = batches.filter((batch) => batch.lotNumber !== largestBatch.lotNumber)
  return [
    createBatchObject(largestBatch, medCode, largestBatch.quantity, isFirstBatch ? "med1" : undefined),
    ...getMAContainedInitialValue(remainingBatches, unitCount - largestBatch.quantity, medCode, false),
  ]
}

const getMRInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  catalogAuthor: Reference,
  patientRef: Reference,
  encounterRef?: Reference,
): MedicationRequest => {
  const mkDefaultDosages = medicationKnowledge.administrationGuidelines?.[0].dosage?.reduce((prev, dosageArray) => {
    return [...prev, ...dosageArray.dosage]
  }, [] as Dosage[])

  const defaultQuantity = {
    value: dispenseQuantity,
    code: medicationKnowledge.packaging?.type?.coding?.[0].code,
    unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
    system: SYSTEM_VALUES.MK_PACKAGE_TYPE,
  } as Quantity
  const currentDate = new Date().toISOString()

  const mrCategory = [{ coding: [mrCategoryCodes.procedure], text: mrCategoryCodes.procedure.display }]

  const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationKnowledge)

  if (isMKRefrigerated) {
    mrCategory.push({
      coding: [mrCategoryCodes.refrigerated],
      text: mrCategoryCodes.refrigerated.display,
    })
  }

  return {
    medication: { CodeableConcept: medicationKnowledge.code },
    status: "draft",
    intent: "order",
    authoredOn: currentDate,
    subject: patientRef,
    encounter: encounterRef ?? undefined,
    requester: undefined,
    recorder: undefined,
    performer: undefined,
    dosageInstruction: mkDefaultDosages,
    category: mrCategory,
    dispenseRequest: {
      performer: catalogAuthor,
      initialFill: {
        isDfo: true,
        quantity: defaultQuantity,
        duration: { value: 0, code: "d", unit: "day", system: SYSTEM_VALUES.UNITS_MEASURE },
      },
      nextRefillDate: currentDate,
      numberOfRepeatsAllowed: 0,
      quantity: defaultQuantity,
      dispenseInterval: { value: 0, code: "d", unit: "day", system: SYSTEM_VALUES.UNITS_MEASURE },
    },
  }
}

const getMedInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  catalogAuthor: Reference,
  patientRef: Reference,
  invData: InventoryData[],
  encounterRef?: Reference | null,
): ConfigurationItem => {
  const doseQuantity =
    medicationKnowledge.administrationGuidelines?.[0].dosage?.[0].dosage?.[0].doseAndRate?.[0].dose?.Quantity ?? {}

  const medicationAdministrationInitialValues = getMAInitialValues(
    patientRef,
    medicationKnowledge,
    dispenseQuantity,
    doseQuantity,
    invData,
  )

  return {
    medicationRequest: getMRInitialValues(
      medicationKnowledge,
      dispenseQuantity,
      catalogAuthor,
      patientRef,
      encounterRef ?? undefined,
    ),
    medicationAdministration: medicationAdministrationInitialValues,
    invData,
    medBaseDose: doseQuantity,
    medTotalDose: {
      ...doseQuantity,
      value: (doseQuantity.value ?? 1) * dispenseQuantity,
    },
    ...getMassageInitialValues({
      batchesCount: ((medicationAdministrationInitialValues?.contained ?? []) as Medication[])?.length,
    }),
  }
}

const getMassageInitialValues = ({ batchesCount = 1 }: { batchesCount?: number } = {}): ConfigurationItem => ({
  bodySite: { coding: [...Array.from({ length: batchesCount }, () => ({ code: undefined }))] },
  zone: bodyZonesCodes[0],
})

const getObservationInitialValue = (
  patientRef: Reference,
  codes: Coding[],
  value: string,
  performer: Reference,
): Observation => ({
  category: [
    {
      text: "Laboratory",
      coding: [
        {
          code: "laboratory",
          system: SYSTEM_VALUES.OBSERVATION_CATEGORY,
          display: "Laboratory",
        },
      ],
    },
  ],
  value: !Number.isNaN(Number.parseFloat(value))
    ? {
        Quantity: {
          value: Number.parseFloat(value),
        },
      }
    : {
        string: value,
      },
  resourceType: "Observation",
  status: "final",
  code: { coding: codes },
  issued: new Date(),
  subject: patientRef,
  performer: [performer],
})

const getDosageTiming = (mr: MedicationRequest, timingRepeatCode: string) =>
  mr?.dosageInstruction?.find((d) => d?.timing?.repeat?.when?.[0] === timingRepeatCode)

const getSupplementId = (supplement?: Supplement) => {
  const supplementId = supplement?.mr.id
  const supplementCode = getCommonCode({
    codes: supplement?.mr.medication?.CodeableConcept?.coding,
    fallback: supplement?.mr.medication?.CodeableConcept?.coding?.[0]?.code,
  })

  return supplementId ?? supplementCode
}

export {
  convertProcedureKindToProductType,
  generateCalculatorResultFromCarePlan,
  getDosageTiming,
  getDoseNumberValue,
  getInitialValues,
  getMAInitialValues,
  getMassageInitialValues,
  getMedInitialValues,
  getMRInitialValues,
  getObservationInitialValue,
  getProcedureKind,
  getSupplementId,
}
