import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { FC } from "react"

import { Accordion, ModulesId, StackedListItem } from "commons"
import { proceduresItemModel, usePatientProcedures } from "procedures"
import { useChartContext } from "chart-view"

import { WrapUpSection } from "./WrapUpSection"
import { renderDetails } from "./utils"

const ProceduresSection: FC<Props> = ({ patientId, encounter, icon }) => {
  const { showModule } = useChartContext()

  const { proceduresData, isLoading, total } = usePatientProcedures({ patientId, encounter })

  const goToDetails = (procedureId: string) => {
    showModule({ module: ModulesId.PROCEDURES, moduleParams: { procedureId } })
  }

  return (
    <WrapUpSection
      isEmpty={!total}
      icon={icon}
      emptyMesage="No procedures activated during this encounter"
      className="w-10/12 self-end z-50"
      sectionTitle="Procedures"
      isLoading={isLoading}
    >
      <Accordion
        data={proceduresData ?? []}
        headerTemplate={(procedureData) => (
          <StackedListItem
            className="pt-2 pb-4 pr-7"
            modelData={proceduresItemModel(procedureData, () => goToDetails(procedureData.procedure.id as string))}
          />
        )}
        headerClassName="paddingless @container"
        expandIcon="pi pi-plus text-sm right-0"
        collapseIcon="pi pi-minus text-sm right-0"
        contentTemplate={({ procedure: { note } }) =>
          renderDetails({
            notes: note,
          })
        }
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounter: string
  icon: IconDefinition
}

export { ProceduresSection }
