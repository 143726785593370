import type { FC } from "react"

import { CodeableConceptField, DateField } from "commons"
import { appointmentTypesCodes } from "data"

const EncounterForm: FC = () => (
  <div className="space-y-3">
    <CodeableConceptField field="type[0]" label="Type" codes={appointmentTypesCodes} />
    <DateField field="period.start" label="Start" stringFormatType="ISO_8601_DATETIME" showTime />
  </div>
)

export { EncounterForm }
