import type { AppModule } from "commons"

import { PatientModule } from "./modules/patient/PatientModule"
import { PatientInformation } from "../patients/components/PatientInformation"
import { PatientInformationKnowledge } from "../patients/knowledge/PatientInformationKnowledge"
import { EncounterModule } from "./modules/encounter/EncounterModule"
import { EncountersKnowledge, OpenEncounter } from "../encounter"
import { CalendarModule } from "./modules/calendar/CalendarModule"
import { PatientAppointments } from "../appointments/components/PatientAppointments"
import { CommunicationModule } from "./modules/communication/CommunicationModule"
import { MessagesContainer } from "../communication/components/MessagesContainer"
import { EmailsModule } from "./modules/communication/EmailsModule"
import { EmailsContainer } from "../email/components/EmailsContainer"
import { AllergyModule } from "./modules/allergy/AllergyModule"
import { AllergiesKnowledge } from "../allergies/knowledge/AllergiesKnowledge"
import { ConditionsModule } from "./modules/condition/ConditionsModule"
import { ConditionsKnowledge } from "../conditions/knowledge/ConditionsKnowledge"
import { VitalsModule } from "./modules/vital/VitalsModule"
import { VitalsKnowledge } from "../vitals/components/VitalsKnowledge"
import { IntakeModule } from "./modules/intake/IntakeModule"
import { Intake } from "../intake/components/Intake"
import { SummaryModule } from "./modules/summary/SummaryModule"
import { PatientSummary } from "../summary/components/PatientSummary"
import { Patient360Module } from "./modules/patient360/Patient360Module"
import { Patient360 } from "../patient360/components/Patient360"
import { LabsOrdersModule } from "./modules/laboratory/LabsOrdersModule"
import { LaboratoryOrderContainer } from "../laboratory-orders/components/LaboratoryOrderContainer"
import { LaboratoryKnowledge } from "../laboratory-orders/knowledge/LaboratoryKnowledge"
import { DosespotModule } from "./modules/medication/DosespotModule"
import { DosespotContainer } from "../dosespot/components/DosespotContainer"
import { MedicationRequestModule } from "./modules/medication/MedicationRequestModule"
import { MedicationRequestContainer } from "../medication-requests/components/MedicationRequestContainer"
import { MedicationKnowledge } from "../medication-requests/components/knowledge/MedicationKnowledge"
import { ProceduresModule } from "./modules/procedure/ProceduresModule"
import { ProceduresContainer } from "../procedures/components/ProceduresContainer"
import { MCModule } from "./modules/mc/MCModule"
import { MCContainer } from "../mc/components/MCContainer"
import { PlansModule } from "./modules/plan/PlansModule"
import { PlansContainer } from "../plans/components/PlansContainer"
import { EPrescribeModule } from "./modules/eprescribe/EPrescribeModule"
import { EPrescribeContainer } from "../eprescribe/components/EPrescribeContainer"
import { SurveyModule } from "./modules/survey/SurveyModule"
import { PatientSurveys } from "../surveys/components/PatientSurveys"
import { SurveysKnowledge } from "../surveys/knowledge/SurveysKnowledge"
import { InvoiceModule } from "./modules/invoice/InvoiceModule"
import { InvoiceContainer } from "../invoices/components/InvoiceContainer"
import { InvoiceKnowledge } from "../invoices/knowledge/InvoiceKnowledge"
import { CheckoutModule } from "./modules/checkout/CheckoutModule"
import { CheckoutContainer } from "../orders/components/CheckoutContainer"

const AppModules: AppModule[] = [
  new IntakeModule(<Intake />),
  new SummaryModule(<PatientSummary />),
  new PatientModule(<PatientInformation />, <PatientInformationKnowledge />),
  new VitalsModule(<VitalsKnowledge />),
  new MedicationRequestModule(<MedicationRequestContainer />, <MedicationKnowledge />),
  new LabsOrdersModule(<LaboratoryOrderContainer />, <LaboratoryKnowledge />),
  new ConditionsModule(<ConditionsKnowledge />),
  new AllergyModule(<AllergiesKnowledge />),
  new EncounterModule(<OpenEncounter />, <EncountersKnowledge />),
  new CalendarModule(<PatientAppointments />),
  new InvoiceModule(<InvoiceContainer />, <InvoiceKnowledge />),
  new SurveyModule(<PatientSurveys />, <SurveysKnowledge />),
  new CommunicationModule(<MessagesContainer />),
  new EmailsModule(<EmailsContainer />),
  new ProceduresModule(<ProceduresContainer />),
  new Patient360Module(<Patient360 />),
  new EPrescribeModule(<EPrescribeContainer />),
  new PlansModule(<PlansContainer />),
  new MCModule(<MCContainer />),
  new DosespotModule(<DosespotContainer />),
  new CheckoutModule(<CheckoutContainer />),
]

export { AppModules }
