import type { Reference } from "fhir"
import { type AutoCompleteCompleteEvent, type AutoCompleteProps, AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useMemo, useState } from "react"

import { useOrganizationContext } from "organization"

const DataAutocomplete: FC<Props> = ({
  dataToHandle,
  inputClassName,
  disabled,
  placeholder,
  error,
  className,
  handleSelect,
  ...autocompleteProps
}) => {
  const { organizationPractitionerRefs, roomsRefs, devicesRefs } = useOrganizationContext()
  const [value, setValue] = useState<string>()
  const [suggestionList, setSuggestionList] = useState<Reference[]>([])

  const getHandledData = useMemo(() => {
    switch (dataToHandle) {
      case "practitioners":
        return organizationPractitionerRefs
      case "devices":
        return devicesRefs
      case "rooms":
        return roomsRefs

      default:
        return []
    }
  }, [organizationPractitionerRefs, roomsRefs, devicesRefs])

  const searchItems = useCallback(
    (event: AutoCompleteCompleteEvent) => {
      let suggestions = Array<Reference>()
      if (!event.query.trim().length) {
        suggestions = [...getHandledData]
      } else {
        suggestions = getHandledData.filter(({ display }) => {
          return display?.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }

      setSuggestionList(suggestions)
    },
    [getHandledData],
  )

  return (
    <div className={classNames("flex flex-col", className)}>
      <label htmlFor={`${dataToHandle}-autocomplete`} className=" capitalize text-sm font-medium text-gray-700 mb-2">
        {dataToHandle}
      </label>

      <AutoComplete
        id={`${dataToHandle}-autocomplete`}
        field="display"
        disabled={disabled}
        suggestions={suggestionList}
        delay={400}
        minLength={1}
        completeMethod={searchItems}
        forceSelection
        className={classNames("p-inputtext-sm", { "p-invalid": error }, inputClassName)}
        placeholder={placeholder}
        onChange={(e) => setValue(e.value)}
        onSelect={(e) => {
          handleSelect(e.value)
          setValue(undefined)
        }}
        appendTo="self"
        panelClassName="w-full"
        value={value}
        dropdown
        {...autocompleteProps}
      />
    </div>
  )
}

type Props = {
  error?: unknown
  placeholder?: string
  disabled?: boolean
  inputClassName?: string
  className?: string
  handleSelect(item?: Reference): void
  dataToHandle: "practitioners" | "rooms" | "devices"
} & AutoCompleteProps

export { DataAutocomplete }
