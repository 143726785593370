import { faCalendarDays, faCalendarExclamation, faClock } from "@fortawesome/pro-regular-svg-icons"
import { type Encounter, codeableConceptAsString } from "fhir"
import { isSameDay } from "date-fns/isSameDay"

import type { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

const encounterModelBuilder = (encounter: Encounter): StackedListItemProps => {
  let encounterDate = ""
  let encounterTime = ""
  let encounterEnd = ""

  if (encounter.period) {
    const start = new Date(encounter.period.start as string)
    const end = !!encounter.period?.end && encounter.status === "finished" && new Date(encounter.period.end)

    const sameDay = end && isSameDay(start, end)

    const endDateOrTime = end
      ? formatDate(end, sameDay ? formatsByTypes.TIME : formatsByTypes.LONG_DATETIME_WITH_TIMEZONE)
      : ""
    encounterDate = formatDate(start, formatsByTypes.LONG_DATE)
    encounterTime = `${formatDate(start, formatsByTypes.TIME)} ${sameDay ? `to ${endDateOrTime}` : ""}`
    encounterEnd = !sameDay ? endDateOrTime : ""
  }

  return {
    leftData: [
      {
        lineItems: [{ name: "Type", value: codeableConceptAsString(encounter.type?.[0]) }],
      },
      ...(encounterDate
        ? [
            {
              lineItems: [
                {
                  name: "Date",
                  value: encounterDate,
                  icon: faCalendarDays,
                },
                {
                  name: "Time",
                  value: encounterTime,
                  icon: faClock,
                },
              ],
            },
            {
              lineItems: [
                ...(encounterEnd && encounter.status === "finished"
                  ? [
                      {
                        name: "End",
                        value: encounterEnd,
                        icon: faCalendarExclamation,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
    ],
    badge: encounter.status === "in-progress" ? { text: encounter.status, colorStyle: "green" } : undefined,
  }
}

export { encounterModelBuilder }
