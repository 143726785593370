import type { Annotation } from "fhir"
import { classNames } from "primereact/utils"

const renderDetails = ({ notes, className }: { notes?: Annotation[]; className?: string }) => {
  const itemNotes = notes?.filter(({ text }) => !!text)
  return (
    <>
      {!!itemNotes?.length && (
        <div className={classNames("flex flex-col text-sm mb-2", className)}>
          <span className="font-medium">Notes:</span>
          {itemNotes.map(({ text }, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
      )}
    </>
  )
}

export { renderDetails }
