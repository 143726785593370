import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import type { FormikHelpers, FormikValues } from "formik"
import type { PropsWithChildren, ReactNode } from "react"
import type { ObjectSchema } from "yup"

import { ConfirmDialog } from "../components/ConfirmDialog"
import { EmptyMessage } from "../components/EmptyMessage"
import { FooterActions } from "../components/FooterActions"
import { Slideover } from "../components/Slideover"
import { DetailsContainer } from "./DetailsContainer"
import { FormContainer } from "./FormContainer"

const DataContainerSlideoverForm = <T extends FormikValues>({
  showSlide,
  slideContent = "form",
  hasData,
  iconDataNotFound = faSearch,
  itemTitleNotFound,
  messageDataNotFound,
  subMessageDataNotFound,
  form,
  formTitle,
  formSubTitle,
  formInitialValue = {} as T,
  validationSchema,
  onSubmit,
  onCancel,
  showSave = true,
  customSaveButton,
  customEmptyAddButton,
  customEmptyAddButtonText,
  customEmptyAddButtonStyle,
  customAddButton,
  customAddIcon,
  customAddButtonText,
  onButtonAddClick,
  detail,
  width = "35%",
  height = "calc(100% - 9.5rem)",
  deleteSupport,
  children,
  showFab = true,
  addButtonClassName,
  customEmptyMessage,
}: PropsWithChildren<Props<T>>) => {
  return (
    <div className="flex flex-col flex-1 overflow-hidden bg-white">
      {!hasData
        ? customEmptyMessage ?? (
            <EmptyMessage
              icon={iconDataNotFound}
              itemTitle={itemTitleNotFound ?? formTitle}
              message={messageDataNotFound}
              subMessage={subMessageDataNotFound}
              customEmptyAddButton={customEmptyAddButton}
              actionText={
                customAddButtonText ?? customEmptyAddButtonText ?? `Create ${formTitle?.toLowerCase() ?? "item"}`
              }
              action={onButtonAddClick}
              disabled={showSlide}
              addButtonClassName={addButtonClassName}
              addButtonStyle={customEmptyAddButtonStyle}
            />
          )
        : children}
      <Slideover showSlide={showSlide} onHide={onCancel} width={width} height={height}>
        {slideContent === "form" ? (
          <FormContainer
            title={formTitle}
            subTitle={formSubTitle}
            initialValue={formInitialValue}
            validationSchema={validationSchema}
            onSubmit={onSubmit ?? (() => ({}))}
            onCancel={onCancel}
            showSave={showSave}
            customSaveButton={customSaveButton}
            enableReinitialize
          >
            {showSlide && form}
          </FormContainer>
        ) : (
          <DetailsContainer title={formTitle as string} onClose={onCancel}>
            {showSlide && detail}
          </DetailsContainer>
        )}
      </Slideover>
      {!showSlide &&
        hasData &&
        showFab &&
        (customAddButton ?? (
          <FooterActions
            actions={[
              {
                label: customAddButtonText ?? `Create new ${formTitle?.toLowerCase() ?? "item"}`,
                icon: customAddIcon ?? faPlus,
                command: () => onButtonAddClick?.(),
              },
            ]}
          />
          /*<FloatingButton
            icon={customAddIcon ?? faPlus}
            label={customAddButtonText ?? `Create new ${formTitle?.toLowerCase() ?? "item"}`}
            className={classNames("fixed bottom-6 right-6", addButtonClassName)}
            onClick={onButtonAddClick}
          />*/
        ))}
      {deleteSupport && (
        <ConfirmDialog
          confirmText={
            deleteSupport.confirmDeleteText ??
            `Are you sure you want to remove this ${formTitle?.toLowerCase() ?? "item"}?`
          }
          actionName={deleteSupport.deleteActionText ?? "Remove"}
          visible={deleteSupport.showDelete || deleteSupport.isDeleting}
          isLoading={deleteSupport.isDeleting}
          onConfirm={() => deleteSupport.onConfirmDelete?.()}
          hideDialog={() => deleteSupport.onCancelDelete?.()}
        />
      )}
    </div>
  )
}

type Props<T> = {
  showSlide: boolean
  slideContent?: "form" | "detail"
  hasData: boolean
  iconDataNotFound?: IconDefinition
  itemTitleNotFound?: string
  messageDataNotFound?: string
  subMessageDataNotFound?: string | boolean
  form: ReactNode
  formTitle?: string
  formSubTitle?: string
  formInitialValue?: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
  onSubmit?(data: T, formikHelpers: FormikHelpers<T>): void
  onCancel(): void
  showSave?: boolean
  customSaveButton?: ReactNode
  customAddButton?: ReactNode
  customEmptyAddButton?: ReactNode
  customEmptyAddButtonText?: string
  customAddIcon?: IconDefinition
  customAddButtonText?: string
  addButtonClassName?: string
  onButtonAddClick?(): void
  detail?: ReactNode
  width?: string
  height?: string
  deleteSupport?: DeleteSupportProps
  showFab?: boolean
  customEmptyAddButtonStyle?: "default" | "text" | "primary" | "floating" | "outlined"
  customEmptyMessage?: ReactNode
}

type DeleteSupportProps = {
  showDelete?: boolean
  confirmDeleteText?: string
  deleteActionText?: string
  isDeleting?: boolean
  onConfirmDelete?(): void
  onCancelDelete?(): void
}

export { DataContainerSlideoverForm }
